import React from "react";
import SocialShareButtons from "./SocialShareButtons_for_result"; // Import SocialShareButtons component

const ResponseSection = ({ response, isDarkMode }) => {
  const formatResponse = (summaryText) => {
    return summaryText
      .replace(/^\d+\.\s+/gm, "")
      .replace(/(\*\*)(.*?)\1/g, "<strong>$2</strong>")
      .replace(/####\s+(.*)/g, "<strong>$1</strong>")
      .replace(/###\s+(.*)/g, "<strong><em>$1</em></strong>")
      .replace(/(^.*?):/gm, "<strong>$1</strong>:") // New pattern to make text before colon bold
      .replace(/\n/g, "<br/>");
  };

  return (
    <div className={`response-section ${isDarkMode ? "dark-mode" : ""}`}>
      <h3>Here are your Perks and Benefits you might be missing:</h3>

      <SocialShareButtons
        searchOption={response.searchOption}
        issuer={response.issuer}
        cardType={response.cardType}
        cardName={response.cardName}
        benefit={response.benefit}
        isDarkMode={isDarkMode}
      />

      {response.summaryOfLinks && (
        <div className="mb-3">
          <h4>Links:</h4>
          <div className={`list_of_links ${isDarkMode ? "dark-mode" : ""}`}>
            <ul className="list-group">
              {response.summaryOfLinks.map((link, index) => (
                <li key={index} className="list-group-item">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {response.summary && (
        <div className="mb-3">
          <h4>Summary:</h4>
          <div className="perk-summary">
            <div
              dangerouslySetInnerHTML={{
                __html: formatResponse(response.summary),
              }}
            />
          </div>
        </div>
      )}
      {response.userInput && (
        <div className="mb-3">
          <h4>User Input:</h4>
          <p>{response.userInput}</p>
        </div>
      )}

      {/* Add SocialShareButtons at the end of the result */}
      <SocialShareButtons
        searchOption={response.searchOption}
        issuer={response.issuer}
        cardType={response.cardType}
        cardName={response.cardName}
        benefit={response.benefit}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default ResponseSection;
