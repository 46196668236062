import ReactPixel from "react-facebook-pixel";

let isPixelInitialized = false;

export const initializeFacebookPixel = (pixelId, userEmail) => {
  const debugMode = process.env.REACT_APP_FACEBOOK_PIXEL_DEBUG === "true";

  if (pixelId) {
    const advancedMatching = { em: userEmail }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: debugMode, // enable logs based on environment variable
    };
    ReactPixel.init(pixelId, advancedMatching, options);
    ReactPixel.pageView(); // For tracking page views
    isPixelInitialized = true;
  } else {
    console.warn("Facebook Pixel ID is not provided.");
  }
};

export const trackPageView = (pageName) => {
  if (isPixelInitialized) {
    ReactPixel.track("PageView", {
      content_name: pageName,
      content_category: "Page Views",
    });
  } else {
    console.warn("Facebook Pixel is not initialized.");
  }
};

export const trackSignUp = () => {
  if (isPixelInitialized) {
    ReactPixel.track("SignUp", {
      content_name: "Sign Up",
      content_category: "User Actions",
    });
  } else {
    console.warn("Facebook Pixel is not initialized.");
  }
};

export const trackLogin = () => {
  if (isPixelInitialized) {
    ReactPixel.track("Login", {
      content_name: "Login",
      content_category: "User Actions",
    });
  } else {
    console.warn("Facebook Pixel is not initialized.");
  }
};

export const trackProfileUpdate = () => {
  if (isPixelInitialized) {
    ReactPixel.track("ProfileUpdate", {
      content_name: "Profile Update",
      content_category: "User Actions",
    });
  } else {
    console.warn("Facebook Pixel is not initialized.");
  }
};

export const trackButtonClick = (buttonName) => {
  if (isPixelInitialized) {
    ReactPixel.track("ButtonClick", {
      content_name: buttonName,
      content_category: "User Actions",
    });
  } else {
    console.warn("Facebook Pixel is not initialized.");
  }
};

export const handleFormSubmission = (formName, pageName) => {
  if (isPixelInitialized) {
    ReactPixel.trackCustom("FormSubmission", {
      content_name: formName,
      content_category: "Form Submissions",
      page_name: pageName,
    });
  } else {
    console.warn("Facebook Pixel is not initialized.");
  }
};
