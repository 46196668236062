// api/apiService.js

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log("API Base URL:", REACT_APP_API_BASE_URL);

export const fetchCardInfoFromExternalApiAndLLM = async (payload) => {
  try {
    const response = await fetch(`${REACT_APP_API_BASE_URL}/api/cardPerksLLM`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(
        `External API fetch failed with status: ${response.status}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data from external API:", error);
    return null;
  }
};

export const fetchCardInfoByPerksFromExternalApiAndLLM = async (payload) => {
  try {
    console.log("Fetching card info by perks from external API:", payload);

    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/api/findCardByPerks`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error(
        `External API fetch failed with status: ${response.status}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data from external API:", error);
    return null;
  }
};

export const fetchCardInfoFromMongoDB = async (issuer, cardType, cardName) => {
  try {
    console.log("Fetching card info from MongoDB:", issuer, cardType, cardName);
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/api/cardPerks?issuer=${issuer}&card_type=${cardType}&card_name=${cardName}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok && response.status === 404) {
      throw new Error(`Not found in the Mango DB status: ${response.status}`);
    } else if (!response.ok) {
      throw new Error(`MongoDB fetch failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data from MongoDB:", error);
    return null;
  }
};

export const saveCardInfoToMongoDB = async (cardData, user_id) => {
  try {
    cardData.user_id = user_id;
    const payload = JSON.stringify(cardData);

    console.log("Saving card info to MongoDB:", payload);
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/api/saveCardPerks`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: payload,
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error(
        `Failed to save data to MongoDB: ${response.status}`,
        errorData
      );
      throw new Error(`Failed to save data to MongoDB: ${response.status}`);
    }
  } catch (error) {
    console.error("Error saving card info to MongoDB:", error);
  }
};

export const saveUserEmailAndCardId = async (userData) => {
  try {
    const payload = JSON.stringify(userData);
    console.log("Saving email and card ID:", payload);

    const response = await fetch(`${REACT_APP_API_BASE_URL}/api/userCards`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`Failed to save email and card ID: ${response.status}`);
    }
  } catch (error) {
    console.error("Error saving email and card ID:", error);
  }
};
