import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Cookies from "js-cookie"; // Import Cookies from js-cookie

const CookieSettingsModal = ({ show, handleClose, isDarkMode }) => {
  const [preferences, setPreferences] = useState({
    necessary: true,
    preferences: true, // Pre-select preferences
    statistics: true, // Pre-select statistics
    marketing: true, // Pre-select marketing
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSaveChanges = () => {
    // Save preferences to cookies
    Cookies.set("cookiePreferences", preferences);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={isDarkMode ? "bg-dark text-white" : ""}
    >
      <Modal.Header
        closeButton
        className={isDarkMode ? "bg-dark text-white" : ""}
      >
        <Modal.Title>Cookie Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className={isDarkMode ? "bg-dark text-white" : ""}>
        <Form>
          <Form.Check
            type="checkbox"
            label="Necessary"
            name="necessary"
            checked={preferences.necessary}
            disabled
          />
          <Form.Check
            type="checkbox"
            label="Preferences"
            name="preferences"
            checked={preferences.preferences}
            onChange={handleCheckboxChange}
          />
          <Form.Check
            type="checkbox"
            label="Statistics"
            name="statistics"
            checked={preferences.statistics}
            onChange={handleCheckboxChange}
          />
          <Form.Check
            type="checkbox"
            label="Marketing"
            name="marketing"
            checked={preferences.marketing}
            onChange={handleCheckboxChange}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className={isDarkMode ? "bg-dark text-white" : ""}>
        <Button
          variant={isDarkMode ? "secondary" : "light"}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant={isDarkMode ? "primary" : "dark"}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieSettingsModal;
