import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../components/Profile";
import { handleFormSubmission, trackPageView } from "../utils/FacebookPixel"; // Import the functions

const ProfilePage = ({ user, isDarkMode, onProfileUpdate }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    trackPageView("Profile Page"); // Track page view
  }, [user, navigate]);

  const handleProfileUpdate = () => {
    onProfileUpdate();
    handleFormSubmission("Profile Update Form", "Profile Page"); // Track profile update form submission
  };

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Profile</h2>
      {user ? (
        <Profile
          user={user}
          isDarkMode={isDarkMode}
          onProfileUpdate={handleProfileUpdate}
        />
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};

export default ProfilePage;
