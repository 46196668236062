import React from "react";

const PrivacyPolicyPage = ({ isDarkMode }) => (
  <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
    <h2 className="text-center">Privacy Policy</h2>
    <p>Your privacy policy content goes here...</p>
  </div>
);

export default PrivacyPolicyPage;
