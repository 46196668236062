import React from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const Header = ({ toggleSidebar, user, isLoginEnabled, isSignupEnabled }) => (
  <header className="app-header text-center mb-4">
    <button className="btn btn-link menu-toggle" onClick={toggleSidebar}>
      <FaBars size={24} />
    </button>
    <h1>Welcome to Perks FOMO</h1>
    {!user && (
      <div className="auth-links mt-3">
        {isLoginEnabled && (
          <Link to="/login" className="btn btn-primary me-2">
            Login
          </Link>
        )}
        {isSignupEnabled && (
          <Link to="/signup" className="btn btn-secondary me-2">
            Signup
          </Link>
        )}
        <Link to="/waitlist" className="btn btn-info">
          Join Waitlist
        </Link>
      </div>
    )}
  </header>
);

export default Header;
