import React from "react";

const TermsOfServicePage = ({ isDarkMode }) => (
  <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
    <h2 className="text-center">Terms of Service</h2>
    <p>Your terms of service content goes here...</p>
  </div>
);

export default TermsOfServicePage;
