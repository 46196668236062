import React, { useEffect } from "react";
import WaitlistForm from "../components/WaitlistForm";
import { trackPageView } from "../utils/FacebookPixel"; // Import the function

const WaitlistPage = ({ setUser, isDarkMode }) => {
  useEffect(() => {
    trackPageView("Waitlist Page"); // Track page view
  }, []);

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Join Our Waitlist</h2>
      <WaitlistForm setUser={setUser} isDarkMode={isDarkMode} />
    </div>
  );
};

export default WaitlistPage;
